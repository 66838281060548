import(/* webpackMode: "eager", webpackExports: ["FooterForm"] */ "/vercel/path0/components/layout/footer/form/FooterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/vercel/path0/components/layout/navigation/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedIcon"] */ "/vercel/path0/components/lib/atoms/animated-icon/AnimatedIcon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/lib/atoms/typography/Typography.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ImagesSlideshow"] */ "/vercel/path0/components/lib/molecules/images-slideshow/ImagesSlideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotFound"] */ "/vercel/path0/components/pages/not-found/NotFound.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/footer/footer_bottom_waves.svg");
