"use client";

import { cn } from "@utils/tailwind";
import NotFoundLogo from "./NotFoundLogo";
import { useRedirectToHome } from "./use-redirect-to-home";

export const NotFound = () => {
  const remaining = useRedirectToHome(5);

  return (
    <main className="bg-green-dark py-64">
      <section className="container-flexible">
        <div
          className={cn(
            "flex flex-col gap-14 pb-12 text-blue-light",
            "sm:pb-14",
            "md:gap-12 md:pb-24",
            "2xl:flex-row 2xl:items-center 2xl:justify-start 2xl:gap-24"
          )}
        >
          <h1 className="t-subpage-h1 text-nowrap">404: Not found</h1>
          <NotFoundLogo />
        </div>
        <div className="t-body-b1 flex flex-col text-blue-light">
          <span>Sorry, we can’t find that page...</span>
          <span>Please wait to be redirected ({remaining}s).</span>
        </div>
      </section>
    </main>
  );
};
